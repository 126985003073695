




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import Translation from '@/components/Translation.vue';
import VueScrollTo from 'vue-scrollto';

@Component({
  components: {
    Translation,
  },
})
export default class Translations extends Vue {
  @Prop() pageNumber: number;
  @Prop() wordlist: boolean;
  @Prop() wordHash: string;
  @Prop() force: boolean;

  get translations() {
    return this.$store.state.translations.pageTranslations;
  }

  get stateWordList() {
    return this.$store.state.translations.wordList;
  }

  get showNextButton() {
    return this.pageNumber && this.pageNumber < this.$store.state.translations.countOfPages;
  }

  get showPrevButton() {
    return this.pageNumber && this.pageNumber > 1;
  }

  @Watch('pageNumber')
  pageNumberWatch(to, from) {
    this.loadPage(to > from);
  }

  mounted() {
    this.loadPage();
  }

  async loadPage(animate = false) {
    if (animate) {
      this.animate();
    }

    if (this.$store.state.translations.countOfPages === 0) {
      await this.$store.dispatch('getCountOfTranslationsPages');
    }

    const pageNumber = this.pageNumber || this.$store.state.translations.currentPage;
    if (
      (this.$store.state.translations.countOfPages === 0 && pageNumber === 0) ||
      (pageNumber > 0 && pageNumber <= this.$store.state.translations.countOfPages) ||
      (this.$route.name !== 'home' && (!this.translations || this.translations.length === 0))
    ) {
      await this.$store.dispatch('getTranslationsPage', { pageNumber: pageNumber || 1, force: this.force });
    } else {
      const page = Math.min(Math.max(1, pageNumber || 0), this.$store.state.translations.countOfPages);
      if (page != pageNumber) {
        this.$router.replace({ name: 'home', query: { page: page.toString() } });
      }
    }

    if (this.$route.name === 'home') {
      this.$store.commit('updateWordList', this.wordlist);
    }
  }

  getLink(direction: 1 | -1): RawLocation {
    const location: RawLocation = { name: 'home', query: { page: (this.pageNumber + 1 * direction).toString() } };

    if (this.wordlist) {
      location.query.wordlist = 'true';
    }

    return location;
  }

  onTranslationClick({ event, id }) {
    if (this.wordHash != id) {
      this.$router.replace({ name: this.$route.name, query: this.$route.query, hash: `#${id}` });
    }

    event.cancelBubble = true;
  }

  animate() {
    if (!this.wordHash) {
      VueScrollTo.scrollTo({
        container: '#app',
        easing: 'ease-in',
        offset: 0,
        force: false,
        cancelable: true,
        x: false,
        y: true,
      });
    }
  }

  openEdit(id) {
    window.open('edit.html?id=' + (id || ''), 'Edit', 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=900');
  }
}
